import * as React from 'react'
import styled from 'styled-components'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { useTheme, ThemeProvider } from '@bestyled/contrib-use-color-mode'
import { Template } from '../types'

const SpecialistBookingPage: Template = (props) => {
  const { location, pageContext } = props
  const { specialist } = pageContext
  const theme = useTheme()

  return (
    <ThemeProvider
      theme={{ ...theme, colors: { ...theme.colors, background: '#f4f4f4' } }}
    >
      <StyledLayout
        location={location}
        basePath={pageContext.basePath}
        locale={pageContext.locale}
        languages={pageContext.languages}
        translations={pageContext.translations}
      >
        <SEO
          pathname={location.pathname}
          title={specialist.name}
          description={specialist.title}
        />
        <StyledIframe
          src={specialist.bookings}
          width="100%"
          height="100%"
          scrolling="yes"
        ></StyledIframe>
      </StyledLayout>
    </ThemeProvider>
  )
}

export default SpecialistBookingPage

const StyledIframe = styled.iframe`
  margin-top: 20px;
  left: 0;
  width: 100vw;
  z-index: 1;
  position: relative;
  border: 0;
  height: 100vh;
`

const StyledLayout = styled(Layout)`
  background: #f4f4f4;
`
